var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "TripComponent row p-3 emobg-border-2 emobg-border-radius-small emobg-background-color-white",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "col-12 d-flex justify-content-between align-items-center emobg-border-bottom-2 emobg-border-color-ground mb-2 cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.getDetails(_vm.trip.startTime, _vm.trip.endTime)
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
            _c(
              "p",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "ui-badge",
                  {
                    staticClass: "mr-1 ml-n1",
                    attrs: { "data-test-id": `trip-details-${_vm.index}` },
                  },
                  [_vm._v(" " + _vm._s(_vm.index) + " ")]
                ),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatUtc(
                        _vm.trip.startTime,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    ) +
                    " - " +
                    _vm._s(
                      _vm.formatUtc(
                        _vm.trip.endTime,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    ) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "my-2 emobg-color-ink-light",
                attrs: { "data-test-id": "trip-details-duration-distance" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.distanceLabel) +
                    ", total time " +
                    _vm._s(_vm.formatStopWatch(_vm.duration)) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "ui-button",
            {
              attrs: {
                "data-test-id": `carrental-sngle-trip-${_vm.index}-export-button`,
                face: _vm.FACES.outline,
                loading: _vm.isExportSingleTripLoading && !_vm.exportDisabled,
                disabled: _vm.exportDisabled,
                square: "",
              },
              on: { clickbutton: _vm.exportTripCsv },
            },
            [
              _c("ui-icon", {
                attrs: {
                  icon: _vm.ICONS.download,
                  color: _vm.COLORS.primary,
                  size: _vm.SIZES.small,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-1" },
        [
          _c("ui-icon", {
            staticClass: "mr-1 ml-n2",
            attrs: { icon: _vm.ICONS.location, color: _vm.COLORS.success },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-11" }, [
        _c("strong", { staticClass: "emobg-body-2" }, [
          _vm._v("Start location"),
        ]),
        _c(
          "p",
          {
            staticClass: "mb-3 ellipsis",
            attrs: { "data-test-id": "trip-details-start-address" },
          },
          [_vm._v(" " + _vm._s(_vm.getLocationLabel(_vm.startAddress)) + " ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "col-1" },
        [
          _c("ui-icon", {
            staticClass: "mr-1 ml-n2",
            attrs: { icon: _vm.ICONS.pin, color: _vm.COLORS.primary },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-11" }, [
        _c("strong", { staticClass: "emobg-body-2" }, [_vm._v("End location")]),
        _c("p", { attrs: { "data-test-id": "trip-details-end-address" } }, [
          _vm._v(" " + _vm._s(_vm.getLocationLabel(_vm.endAddress)) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }