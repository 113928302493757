<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatStopWatch,
  formatUtc,
  TIME_ZONE,
  toQueryString,
} from '@emobg/web-utils';
import md5 from 'md5';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import carRental from '../../../store/CarrentalModuleMap';
import { mileageUnitMixin } from '../../../mixins';

export default {
  name: 'TripComponent',
  mixins: [mileageUnitMixin],
  props: {
    trip: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    exportDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startAddress: '',
      endAddress: '',
      isAddressLoading: false,
    };
  },
  computed: {
    ...mapState(carRental.trip.details, {
      address: state => state.address,
      isExportSingleTripLoading: state => state.exportSingleTrip.STATUS.LOADING,
    }),
    ...mapState(carRental.vehicle.telemetry, {
      isGpsLoading: state => state.telemetry.STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    duration() {
      const { startTime, endTime } = this.trip;
      return moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    },
    distanceLabel() {
      const { kilometersDriven } = this.trip;
      return kilometersDriven
        ? `${this.formatDistance(kilometersDriven, this.mileageUnit, 0)} driven`
        : FALLBACK_MESSAGE.noData;
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.TIME_ZONE = TIME_ZONE;
    this.getAddress(this.trip.startGps, 'startAddress');
    this.getAddress(this.trip.endGps, 'endAddress');
    this.vehicleVin = this.$route.params.vehicleVin;
  },
  methods: {
    formatUtc,
    ...mapActions(carRental.trip.details, [
      'getReverseGeocode',
      'getVehicleTripsDetails',
    ]),
    ...mapActions(carRental.vehicle.telemetry, [
      'getVehicleTelemetry',
    ]),
    async getAddress(coordinates, scope) {
      if (!isEmpty(coordinates)) {
        this.isAddressLoading = true;
        const { latitude, longitude } = coordinates;
        const query = { lat: latitude, lng: longitude };
        const tripId = md5(toQueryString(query));
        this[scope] = tripId;

        await this.getReverseGeocode({ tripId, query });
        this.isAddressLoading = false;
      }
    },
    getDetails(startDate, endDate) {
      if (this.isGpsLoading) {
        return;
      }

      const telemetryType = 'gps';
      const query = {
        from: moment(startDate).toISOString(),
        to: moment(endDate).toISOString(),
        types: telemetryType,
      };

      this.getVehicleTelemetry({ vehicleVin: this.vehicleVin, query: toQueryString(query) });
      this.$emit('activeTrip', this.index);
    },
    exportTripCsv(event) {
      event.stopPropagation();
      const dateRange = {
        from: this.trip.startTime,
        to: this.trip.endTime,
      };
      this.$emit('activeTrip', this.index);
      this.$emit('exportTripCsv', dateRange);
    },
    getLocationLabel(addressSource) {
      return this.isAddressLoading
        ? 'Geolocating...'
        : this.address.data[addressSource] || FALLBACK_MESSAGE.noData;
    },
    formatStopWatch,
  },
};
</script>

<template>
  <div
    class="TripComponent row p-3
      emobg-border-2 emobg-border-radius-small emobg-background-color-white"
  >
    <div
      class="col-12 d-flex justify-content-between align-items-center emobg-border-bottom-2 emobg-border-color-ground mb-2 cursor-pointer"
      @click="getDetails(trip.startTime, trip.endTime)"
    >
      <div class="d-flex flex-column align-items-center">
        <p class="d-flex align-items-center">
          <ui-badge
            :data-test-id="`trip-details-${index}`"
            class="mr-1 ml-n1"
          >
            {{ index }}
          </ui-badge>
          {{ formatUtc(trip.startTime, DATE_FORMAT.defaultExtended, operatorTimezone) }} -
          {{ formatUtc(trip.endTime, DATE_FORMAT.defaultExtended, operatorTimezone) }}
        </p>
        <p
          data-test-id="trip-details-duration-distance"
          class="my-2 emobg-color-ink-light"
        >
          {{ distanceLabel }}, total time {{ formatStopWatch(duration) }}
        </p>
      </div>
      <ui-button
        :data-test-id="`carrental-sngle-trip-${index}-export-button`"
        :face="FACES.outline"
        :loading="isExportSingleTripLoading && !exportDisabled"
        :disabled="exportDisabled"
        square
        @clickbutton="exportTripCsv"
      >
        <ui-icon
          :icon="ICONS.download"
          :color="COLORS.primary"
          :size="SIZES.small"
        />
      </ui-button>
    </div>

    <div class="col-1">
      <ui-icon
        :icon="ICONS.location"
        :color="COLORS.success"
        class="mr-1 ml-n2"
      />
    </div>
    <div class="col-11">
      <strong class="emobg-body-2">Start location</strong>
      <p
        data-test-id="trip-details-start-address"
        class="mb-3 ellipsis"
      >
        {{ getLocationLabel(startAddress) }}
      </p>
    </div>

    <div class="col-1">
      <ui-icon
        :icon="ICONS.pin"
        :color="COLORS.primary"
        class="mr-1 ml-n2"
      />
    </div>
    <div class="col-11">
      <strong class="emobg-body-2">End location</strong>
      <p data-test-id="trip-details-end-address">
        {{ getLocationLabel(endAddress) }}
      </p>
    </div>
  </div>
</template>
