<script>
import map from 'lodash/map';
import size from 'lodash/size';
import first from 'lodash/first';
import get from 'lodash/get';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';

import moment from 'moment';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  DATE_FORMAT,
  exportFile,
  formatStopWatch,
  formatUtc,
  navigationErrorHandler,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import GoogleMapTripsComponent from './components/GoogleMapTripsComponent';
import SidePanel from '../../components/SidePanel';
import TripComponent from './components/TripComponent';
import carRental from '../../store/CarrentalModuleMap';
import carRentalRoutes from '../../router/CarrentalRouterMap';
import { carRentalErrors } from '../../const/errors';
import { mileageUnitMixin } from '../../mixins';
import { scopes } from './store/TripDetailsModule';

export default {
  name: 'TripDetailsView',
  components: {
    GoogleMapTripsComponent,
    SidePanel,
    TripComponent,
  },
  mixins: [mileageUnitMixin],
  data() {
    return {
      activeTripIndex: 0,
      tripsCount: 4,
    };
  },
  computed: {
    ...mapState(carRental.trip.details, {
      tripSummaryData: state => state.summary.data,
      isTripSummaryLoading: state => state.summary.STATUS.LOADING,
      tripsCsvData: state => state.exportTrips.data,
      singleTripCsvData: state => state.exportSingleTrip.data,
      isExportTripsLoading: state => state.exportTrips.STATUS.LOADING,
      isExportSingleTripLoading: state => state.exportSingleTrip.STATUS.LOADING,
      isExportTripsLoaded: state => state.exportTrips.STATUS.LOADED,
      isExportSingleTripLoaded: state => state.exportSingleTrip.STATUS.LOADED,
      error: state => state.summary.error || state.details.error || state.exportTrips.error || state.exportSingleTrip.error,
    }),
    ...mapState(carRental.vehicle.trips, {
      dateRange: state => state.dateRange || {},
      vehicleLicensePlate: state => state.vehicleLicensePlate || '',
      reason: state => state.reason,
      userId: state => state.userId,
      comment: state => state.comment,
    }),
    ...mapState(carRental.vehicle.telemetry, {
      tripGpsData: state => state.telemetry.data.gps || [],
      isGpsLoading: state => state.telemetry.STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
      operatorCountryCode: state => state.operators.countryCode,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['activeOperator']),
    tripsBoundaries() {
      const details = map(this.tripGpsData, (tripGps, key) => Object.assign(tripGps, {
        icon: { url: '/carrental/point.svg' },
        uuid: key,
        tooltip: this.getGpsTooltip(tripGps),
      }));

      const hasDetails = size(details) > 1;

      const startPoint = hasDetails ? last(details) : get(first(this.tripSummaryData.trips), 'startGps');
      const finishPoint = hasDetails ? first(details) : get(first(this.tripSummaryData.trips), 'endGps');

      const tripStart = {
        ...startPoint,
        uuid: 'start',
        zIndex: 10000,
        tooltip: 'Start location',
        icon: { url: '/carrental/start_location.svg' },
      };
      const tripFinish = {
        ...finishPoint,
        uuid: 'finish',
        zIndex: 10000,
        tooltip: 'End location',
        icon: { url: '/carrental/end_location.svg' },
      };

      return [tripStart, tripFinish, ...details];
    },
    tripsNumber() {
      return this.tripSummaryData.trips && this.tripSummaryData.trips.length;
    },
    mileage() {
      return get(this, 'tripSummaryData.summary.kilometersDriven', 0);
    },
  },
  watch: {
    isExportTripsLoaded(currentStatus) {
      if (currentStatus && !isEmpty(this.tripsCsvData)) {
        this.createExportFile(this.tripsCsvData);
      }
    },
    isExportSingleTripLoaded(currentStatus) {
      if (currentStatus && !isEmpty(this.singleTripCsvData)) {
        this.createExportFile(this.singleTripCsvData);
      }
    },
    activeOperator() {
      this.redirectToVehiclesSummaryTrips();
    },
    error() {
      this.throwError({ message: this.error.message });
    },
  },
  beforeDestroy() {
    this.clearData({ scopes: [scopes.summary] });
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.vehicleVin = get(this, '$route.params.vehicleVin');
  },
  mounted() {
    if (isEmpty(this.dateRange)) {
      this.throwError({ message: carRentalErrors.invalidDateRange });
      this.redirectToVehiclesSummaryTrips();
    }
  },
  methods: {
    ...mapActions(carRental.trip.details, [
      'getVehicleTripsExport',
      'getVehicleSingleTripExport',
    ]),
    ...mapMutations(carRental.trip.details, [
      'clearData',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.errors, [
      'throwError',
    ]),
    get,
    isEmpty,
    async exportCsv() {
      await this.getVehicleTripsExport({
        vehicleVin: this.vehicleVin,
        data: {
          ...this.dateRange,
          timezone: this.operatorTimezone,
          reason: this.reason,
          comment: this.comment,
          userId: this.userId,
          countryCode: this.operatorCountryCode,
        },
      });
    },
    async exportSingleTripCsv(dateRange) {
      await this.getVehicleSingleTripExport({
        vehicleVin: this.vehicleVin,
        data: {
          ...dateRange,
          timezone: this.operatorTimezone,
          reason: this.reason,
          comment: this.comment,
          userId: this.userId,
          countryCode: this.operatorCountryCode,
        },
      });
    },
    redirectToVehiclesSummaryTrips() {
      this.$router.push(
        {
          name: carRentalRoutes.vehicles.summary.trips,
          params: { vehicleVin: this.vehicleVin },
        },
      ).catch(navigationErrorHandler);
    },
    createExportFile(csvData) {
      const filename = moment().format('[Trips_GPS_Data]YYYY-MM-DD_HH[h]mm[m]SS[s.csv]');
      this.exportFile(csvData, filename);
    },
    getGpsTooltip(tripGps) {
      return `<p>Date/time: ${formatUtc(tripGps.occurredOn, DATE_FORMAT.defaultExtended, this.operatorTimezone)}</p>
      <p>Position: (${tripGps.latitude}, ${tripGps.longitude})</p>`;
    },
    exportFile,
    formatStopWatch,
  },
};
</script>

<template>
  <div class="TripDetailsView">
    <ui-loader
      v-if="isTripSummaryLoading"
      label="Loading content"
      :color="COLORS.ink"
      class="center"
    />
    <SidePanel
      v-else
      data-test-id="trip-details-sidebar"
    >
      <template slot="content">
        <div
          class="my-4 pr-3 pl-6
        emobg-border-right-2 emobg-border-color-primary emobg-border-radius-none
        d-flex justify-content-between
      "
        >
          <p
            data-test-id="carrental-trip-details-summary"
            class="mb-2"
          >
            <span class="emobg-body-2">{{ vehicleLicensePlate }} - {{ vehicleVin }}</span><br>
            This vehicle has <strong>{{ get(this, 'tripSummaryData.summary.numberOfTrips', 0 ) }}</strong> trips,
            the total time is <strong>
              {{ formatStopWatch(get(this, 'tripSummaryData.summary.durationSeconds', 0 )) }},</strong>
            and the distance is <strong>{{ formatDistance(mileage, mileageUnit, 0) }}</strong>.
          </p>
          <ui-button
            data-test-id="carrental-trip-details-export-button"
            :square="true"
            :face="FACES.outline"
            :loading="isExportTripsLoading"
            :disabled="isExportTripsLoaded && isEmpty(tripsCsvData)"
            @clickbutton="exportCsv"
          >
            <ui-icon
              :icon="ICONS.download"
              :color="COLORS.primary"
              :size="SIZES.small"
            />
          </ui-button>
        </div>
        <GoogleMapTripsComponent
          v-if="tripsBoundaries.length && tripsNumber"
          data-test-id="carrental-trip-details-map"
          :marker-sources="tripsBoundaries"
          zoom-control
          class="position-absolute w-100"
        />
        <span
          v-else
          data-test-id="carrental-trip-details-no-data"
          class="center"
        >
          No trips data to show
        </span>
      </template>
      <template slot="sidebar-top">
        <div
          data-test-id="carrental-trip-details-trip-period"
          class="TripDetailsView__summary p-4 mb-2"
        >
          <p class="pb-2 emobg-body-2">
            Trips data for the period:
          </p>
          {{ moment(dateRange.from).format(DATE_FORMAT.defaultExtended) }}
          - {{ moment(dateRange.to).format(DATE_FORMAT.defaultExtended) }}
        </div>
      </template>
      <div
        slot="sidebar"
        class="TripComponent_list"
      >
        <TripComponent
          v-for="(trip, index) in tripSummaryData.trips && tripSummaryData.trips.slice(0, tripsCount)"
          :key="index"
          :data-test-id="`carrental-trip-details-${index}`"
          :trip="trip"
          :index="index + 1"
          :export-disabled="isExportSingleTripLoading && activeTripIndex !== index + 1"
          :class="[
            `m-3`,
            { 'disabled': isGpsLoading && activeTripIndex !== index + 1 },
            `emobg-border-color-${ activeTripIndex === index + 1
              ? 'primary-lighter'
              : 'ground-light'
            }`]"
          @activeTrip="activeTripIndex = $event"
          @exportTripCsv="exportSingleTripCsv"
        />
        <span
          v-if="!tripsNumber"
          data-test-id="carrental-trip-details-no-trips"
          class="center"
        >
          No trips available for selected period
        </span>

        <ui-button
          v-if="tripsNumber > tripsCount"
          data-test-id="carrental-trip-details-show-more-trips"
          class="mx-3 mb-3"
          @clickbutton="tripsCount = tripsCount + 4"
        >
          Show more (showing {{ tripsCount }} of {{ tripsNumber }} trips)
        </ui-button>
      </div>
    </SidePanel>
  </div>
</template>
