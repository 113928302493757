var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TripDetailsView" },
    [
      _vm.isTripSummaryLoading
        ? _c("ui-loader", {
            staticClass: "center",
            attrs: { label: "Loading content", color: _vm.COLORS.ink },
          })
        : _c(
            "SidePanel",
            { attrs: { "data-test-id": "trip-details-sidebar" } },
            [
              _c(
                "template",
                { slot: "content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "my-4 pr-3 pl-6 emobg-border-right-2 emobg-border-color-primary emobg-border-radius-none d-flex justify-content-between",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            "data-test-id": "carrental-trip-details-summary",
                          },
                        },
                        [
                          _c("span", { staticClass: "emobg-body-2" }, [
                            _vm._v(
                              _vm._s(_vm.vehicleLicensePlate) +
                                " - " +
                                _vm._s(_vm.vehicleVin)
                            ),
                          ]),
                          _c("br"),
                          _vm._v(" This vehicle has "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.get(
                                  this,
                                  "tripSummaryData.summary.numberOfTrips",
                                  0
                                )
                              )
                            ),
                          ]),
                          _vm._v(" trips, the total time is "),
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatStopWatch(
                                    _vm.get(
                                      this,
                                      "tripSummaryData.summary.durationSeconds",
                                      0
                                    )
                                  )
                                ) +
                                ","
                            ),
                          ]),
                          _vm._v(" and the distance is "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDistance(
                                  _vm.mileage,
                                  _vm.mileageUnit,
                                  0
                                )
                              )
                            ),
                          ]),
                          _vm._v(". "),
                        ]
                      ),
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            "data-test-id":
                              "carrental-trip-details-export-button",
                            square: true,
                            face: _vm.FACES.outline,
                            loading: _vm.isExportTripsLoading,
                            disabled:
                              _vm.isExportTripsLoaded &&
                              _vm.isEmpty(_vm.tripsCsvData),
                          },
                          on: { clickbutton: _vm.exportCsv },
                        },
                        [
                          _c("ui-icon", {
                            attrs: {
                              icon: _vm.ICONS.download,
                              color: _vm.COLORS.primary,
                              size: _vm.SIZES.small,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.tripsBoundaries.length && _vm.tripsNumber
                    ? _c("GoogleMapTripsComponent", {
                        staticClass: "position-absolute w-100",
                        attrs: {
                          "data-test-id": "carrental-trip-details-map",
                          "marker-sources": _vm.tripsBoundaries,
                          "zoom-control": "",
                        },
                      })
                    : _c(
                        "span",
                        {
                          staticClass: "center",
                          attrs: {
                            "data-test-id": "carrental-trip-details-no-data",
                          },
                        },
                        [_vm._v(" No trips data to show ")]
                      ),
                ],
                1
              ),
              _c("template", { slot: "sidebar-top" }, [
                _c(
                  "div",
                  {
                    staticClass: "TripDetailsView__summary p-4 mb-2",
                    attrs: {
                      "data-test-id": "carrental-trip-details-trip-period",
                    },
                  },
                  [
                    _c("p", { staticClass: "pb-2 emobg-body-2" }, [
                      _vm._v(" Trips data for the period: "),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .moment(_vm.dateRange.from)
                            .format(_vm.DATE_FORMAT.defaultExtended)
                        ) +
                        " - " +
                        _vm._s(
                          _vm
                            .moment(_vm.dateRange.to)
                            .format(_vm.DATE_FORMAT.defaultExtended)
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "TripComponent_list",
                  attrs: { slot: "sidebar" },
                  slot: "sidebar",
                },
                [
                  _vm._l(
                    _vm.tripSummaryData.trips &&
                      _vm.tripSummaryData.trips.slice(0, _vm.tripsCount),
                    function (trip, index) {
                      return _c("TripComponent", {
                        key: index,
                        class: [
                          `m-3`,
                          {
                            disabled:
                              _vm.isGpsLoading &&
                              _vm.activeTripIndex !== index + 1,
                          },
                          `emobg-border-color-${
                            _vm.activeTripIndex === index + 1
                              ? "primary-lighter"
                              : "ground-light"
                          }`,
                        ],
                        attrs: {
                          "data-test-id": `carrental-trip-details-${index}`,
                          trip: trip,
                          index: index + 1,
                          "export-disabled":
                            _vm.isExportSingleTripLoading &&
                            _vm.activeTripIndex !== index + 1,
                        },
                        on: {
                          activeTrip: function ($event) {
                            _vm.activeTripIndex = $event
                          },
                          exportTripCsv: _vm.exportSingleTripCsv,
                        },
                      })
                    }
                  ),
                  !_vm.tripsNumber
                    ? _c(
                        "span",
                        {
                          staticClass: "center",
                          attrs: {
                            "data-test-id": "carrental-trip-details-no-trips",
                          },
                        },
                        [_vm._v(" No trips available for selected period ")]
                      )
                    : _vm._e(),
                  _vm.tripsNumber > _vm.tripsCount
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "mx-3 mb-3",
                          attrs: {
                            "data-test-id":
                              "carrental-trip-details-show-more-trips",
                          },
                          on: {
                            clickbutton: function ($event) {
                              _vm.tripsCount = _vm.tripsCount + 4
                            },
                          },
                        },
                        [
                          _vm._v(
                            " Show more (showing " +
                              _vm._s(_vm.tripsCount) +
                              " of " +
                              _vm._s(_vm.tripsNumber) +
                              " trips) "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }