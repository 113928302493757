import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { formatDistance } from '@emobg/web-utils';

export default {
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      mileageUnit: state => get(state, 'operators.mileageUnit'),
    }),
  },
  methods: { formatDistance },
};
