var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "SidePanel row no-gutters emobg-background-color-white",
      attrs: { "data-test-id": "side_panel" },
    },
    [
      _c(
        "div",
        {
          class: [
            "pr-0",
            `${_vm.isSidePanelOpen ? "col-6 col-md-7 col-lg-9" : "col"}`,
          ],
          attrs: { "data-test-id": "content" },
        },
        [_vm._t("content")],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "SidePanel__sidebar emobg-background-color-white shadow-m",
          class: [
            "pr-0",
            `${_vm.isSidePanelOpen ? "col-6 col-md-5 col-lg-3" : ""}`,
          ],
          style: {
            width: `${_vm.isSidePanelOpen ? "25%" : "5px"}`,
            transition: "width .125s ease",
          },
          attrs: { "data-test-id": "sidebar" },
        },
        [
          _vm._t("sidebar-top"),
          _c(
            "ui-button",
            {
              staticClass:
                "SidePanel__toggle ml-n4 emobg-color-white-contrast position-relative",
              attrs: {
                "data-test-id": "toggle",
                color: _vm.GRAYSCALE.white,
                square: "",
              },
              on: {
                clickbutton: () => (_vm.isSidePanelOpen = !_vm.isSidePanelOpen),
              },
            },
            [
              _c("ui-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: _vm.isSidePanelOpen
                    ? _vm.ICONS.bold.right
                    : _vm.ICONS.bold.left,
                  size: _vm.SIZES.small,
                },
              }),
            ],
            1
          ),
          _vm._t("sidebar"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }