<script>
export default {
  name: 'SidePanel',
  data() {
    return {
      isSidePanelOpen: true,
    };
  },
};
</script>

<template>
  <div
    class="SidePanel row no-gutters emobg-background-color-white"
    data-test-id="side_panel"
  >
    <div
      :class="['pr-0', `${isSidePanelOpen ? 'col-6 col-md-7 col-lg-9' : 'col'}`]"
      data-test-id="content"
    >
      <slot name="content" />
    </div>
    <div
      data-test-id="sidebar"
      class="SidePanel__sidebar emobg-background-color-white shadow-m"
      :class="['pr-0',`${ isSidePanelOpen ? 'col-6 col-md-5 col-lg-3' : '' }`]"
      :style="{'width': `${ isSidePanelOpen ? '25%' : '5px' }`, 'transition': 'width .125s ease'}"
    >
      <slot name="sidebar-top" />
      <ui-button
        data-test-id="toggle"
        :color="GRAYSCALE.white"
        square
        class="SidePanel__toggle
          ml-n4
          emobg-color-white-contrast position-relative"
        @clickbutton="() => isSidePanelOpen = !isSidePanelOpen"
      >
        <ui-icon
          :icon="isSidePanelOpen ? ICONS.bold.right : ICONS.bold.left"
          :size="SIZES.small"
          class="mr-2"
        />
      </ui-button>
      <slot name="sidebar" />
    </div>
  </div>
</template>
